import router from '@/router/index.js';
import NProgress from 'nprogress';

/**
 * 在新的tab页打开
 * @param uri
 */
export function useToTabPage(uri, paramsObject = undefined) {
  const url = router.resolve(uri);
  if (paramsObject) {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(paramsObject)) {
      searchParams.append(key, value);
    }
    window.open(url.href + '?' + searchParams.toString(), '_blank');
  } else {
    window.open(url.href, '_blank');
  }
}

/**
 * 当前页打开
 * @param uri
 * @param paramsObject
 */
export function useToCurrentPage(uri, paramsObject, isLogin = true) {
  router.push({
    path: uri,
    query: paramsObject,
  });
}

/**
 * 跳转首页
 */
export function useToHome() {
  router.replace('/').then((r) => {});
}

/**
 * 关闭浏览器tab页
 */
export function useCloseWindowTab() {
  useToHome();
}

/**
 * 跳转全部课程
 */
export function useToAllCourse() {
  router.replace('/allCourse').then((r) => {});
}

/**
 * 开启进度条
 */
export function showLoadingProcess() {
  NProgress.start();
}

/**
 * 结束进度条
 */
export function hideLoadingProcess() {
  NProgress.done();
}

/**
 * 跳转新的tab页
 * @param url
 */
export function useToOtherPage(url) {
  if (!url || url == '') {
    return;
  }
  window.open(url, '_blank');
}

/**
 * 判断是否是移动设备
 * @returns {boolean}
 */
export function useIsMobileBrowser() {
  // 这些字符串通常出现在移动设备的用户代理中
  const mobileUserAgentPatterns = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /IEMobile/i, /Opera Mini/i, /Mobile/i, /mobile/i, /Windows Phone/i];

  // 获取用户代理字符串
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 检查用户代理字符串是否包含上述任何一个模式
  return mobileUserAgentPatterns.some((pattern) => userAgent.match(pattern));
}

/**
 * 视频移动端横屏播放
 * @param player
 */
export function useVideoFullscreen(player) {
  if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
    // 当前浏览器处于非全屏状态
    if (player.tech_.el_.requestFullscreen) {
      player.tech_.el_.requestFullscreen();
    } else if (player.tech_.el_.mozRequestFullScreen) {
      /* Firefox */
      player.tech_.el_.mozRequestFullScreen();
    } else if (player.tech_.el_.webkitRequestFullscreen) {
      /* Chrome, Safari 和 Opera */
      player.tech_.el_.webkitRequestFullscreen();
      player.tech_.el_.webkitFullscreenElementClass = 'vjs-fullscreen';
    } else if (player.tech_.el_.msRequestFullscreen) {
      /* IE/Edge */
      player.tech_.el_.msRequestFullscreen();
    }
  } else {
    // 当前浏览器处于全屏状态
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari 和 Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }
}

/**
 * 刷新页面
 */
export function useRefreshPage() {
  window.location.reload();
}
