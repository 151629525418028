import FileSaver from 'file-saver';
import { loadScript } from '@paypal/paypal-js';
import axios from 'axios';
import $ from 'jquery';
import { useLoading } from 'vue-loading-overlay';

/**
 * 下载文件
 * @param url
 * @param filename
 */
export function useDownloadFile(url, filename = '') {
  FileSaver.saveAs(url, filename);
}

/**
 * 更新视频源
 * @param player
 * @param newSrc
 */
export function useUpdateVideoSource(player, newSrc) {
  player.src({
    src: newSrc,
    type: 'video/mp4', // 或者其他视频格式
    provider: 'video',
  });
  // 播放新的视频
  player.load();
  player.play();
}

/**
 * 解析富文本
 * @param html
 * @returns {*}
 */
export function useAnalyzeHtml(html) {
  return html;
}

/**
 * 处理音频
 * @param el
 */
export function useSelectAudioHtml(el) {}

/**
 * 动态获取资源
 * @param url
 * @returns {string}
 */
export function useGetAssetsFile(url) {
  return new URL(url, import.meta.url).href;
}

/**
 * 获取环境变量
 * @param key
 * @returns {*}
 */
export function useGetEnv(key) {
  return import.meta.env[key];
}

/**
 * 获取页面url参数
 * @returns {{}}
 */
export function getUrlParams(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg) || window.location.hash.substring(window.location.hash.search(/\?/) + 1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
}

/**
 * paypal处理方法
 * @param $el
 * @param $el
 * @param createOrder
 * @param onCancel
 * @param onError
 */
export function useInitPaypal(loader, $el, currency, onClick, createOrder, onSuccess, onCancel, onError, finallyCallback) {
  $($el).html('');
  const checkbox = document.getElementById('checkId').getElementsByTagName('input')[0];
  loadScript({
    // environment: useGetEnv('VITE_APP_ENV') === 'production' ? 'production' : 'sandbox', //"production" | "sandbox"
    'client-id': import.meta.env.VITE_APP_PAYPAL_CLIENT_ID,
    commit: true,
    components: 'buttons,messages',
    currency: currency,
    debug: false,
  })
    .then(async (paypal) => {
      // start to use the PayPal JS SDK script
      if (paypal) {
        await paypal
          .Buttons({
            onInit(data, actions) {
              let valueStr = checkbox.getAttribute('value');
              if (valueStr === 'true') {
                actions.enable();
              } else {
                actions.disable();
              }
              new MutationObserver(function (mutationsList, observer) {
                for (let mutation of mutationsList) {
                  if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
                    let valueStr = checkbox.getAttribute('value');
                    if (valueStr === 'true') {
                      actions.enable();
                    } else {
                      actions.disable();
                    }
                  }
                }
              }).observe(checkbox, { attributes: true });
              loader.hide();
            },
            onClick(data, actions) {
              onClick();
            },
            style: {
              layout: 'vertical',
              color: 'gold',
              shape: 'rect',
              Tagline: 'false',
              label: 'buynow',
            },
            async createOrder() {
              return await createOrder();
            },
            async onApprove(data) {
              console.log('支付成功', data);
              let xAuthToken = localStorage.getItem('Token');
              try {
                const resp = await axios.post(
                  import.meta.env.VITE_APP_PAYPAL_DOMAIN + 'PayPal/Success_Pay',
                  {
                    PaymentId: data.orderID,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + xAuthToken,
                    },
                  }
                );
                onSuccess && onSuccess(resp);
              } catch (e) {
                console.error(e);
                onError();
              }
              // // Show success message to buyer
              // alert(`Transaction completed by ${details.payer.name.given_name}`);
            },
            onCancel(data) {
              // 取消支付
              // window.location.assign("/your-cancel-page");
              console.log('我取消了支付', data);
              onCancel && onCancel(data);
            },
            onError(err) {
              // 支付出现错误
              // window.location.assign('/your-error-page-here');
              console.log('支付出错了', err);
              onError && onError(err);
            },
          })
          .render($el);
      }
    })
    .catch((err) => {
      console.error('failed to load the PayPal JS SDK script', err);
    })
    .finally(() => {
      finallyCallback();
    });
}

/**
 * 动画
 * @param element
 * @param animation
 * @param prefix
 */
export function useAnimateCSS(element, animation, prefix = 'animate__') {
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
  });
}

/**
 * 等待层
 */
export function useShowLoading() {
  const $loading = useLoading({});
  return $loading.show({
    container: $('.payment_modal')[0],
    loader: 'bars',
    backgroundColor: '#ffffff',
    color: '#0678FF',
    width: 64,
    height: 64,
    opacity: 0.8,
  });
}
