import request from '@/common/request.js';
import { useLocalCacheStore } from '@/stores/localCache.js';
import axios from 'axios';
import BusinessContextEvent from '@/common/BusinessContextEvent.js';
import { langToBack } from '@/hooks/sysDict.js';
import i18n from '@/locales/index.js';

/**
 * 课程分类
 * @returns {Promise<void>}
 */
export async function useCourseCategoryApi() {
  return request({
    url: '/CourseCategory/GetCourseCategoryList',
    method: 'get',
    params: {},
  });
}

/**
 * 推荐课程
 * pageSize
 * @param pageSize
 * @returns {Promise<*>}
 */
export async function useRecommendCourse(pageSize, excludeId = '') {
  return request({
    url: '/Course/GetIsrecommendCourseList',
    method: 'get',
    params: { pageSize: pageSize, courseId: excludeId },
  });
}

/**
 *
 * @param pageSize
 * @returns {Promise<*>}
 */
export async function useRecommendSetMeal(pageSize) {
  return request({
    url: '/Package/GetPackageList',
    method: 'get',
    params: { pageSize: pageSize, pageIndex: 1 },
  });
}

/**
 * 获取用户基本信息
 */
export function useGetUserinfo(callback) {
  let localCache = useLocalCacheStore();
  request({
    url: 'Auth/GetCurrentUser',
    method: 'get',
    params: {},
  }).then((resp) => {
    localCache.setUserInfo(resp.data);
    if (callback) callback();
  });
}

/**
 * 校验是否登录，没有登录弹出登录框
 * @param callback
 */
export function useCheckIsLogin(callback, dialog = true, errCb = undefined) {
  const xAuthToken = localStorage.getItem('Token');
  if (!xAuthToken) {
    if (dialog) {
      BusinessContextEvent.$emit('loginHandle');
    }
    if (errCb) errCb();
    return;
  }
  let localCache = useLocalCacheStore();
  axios
    .get(import.meta.env.VITE_APP_API_URL + 'Auth/GetCurrentUser', {
      headers: {
        Authorization: 'Bearer ' + xAuthToken,
      },
      params: {
        lang: langToBack[i18n.global.locale.value],
      },
    })
    .then((resp) => {
      localCache.setUserInfo(resp.data.data);
      if (callback) callback();
    })
    .catch((err) => {
      console.error(err);
      if (err.response.status === 401) {
        localCache.setUserInfo(undefined);
        localStorage.removeItem('Token');
        if (dialog) {
          BusinessContextEvent.$emit('loginHandle');
        }
        if (errCb) errCb();
      }
    });
}

/**
 * 检查用户是否购买了该课程
 * @param courseId
 */
export async function useCheckBuyCourse(courseId, freeType) {
  return await request({
    url: '/Course/IsActivation',
    method: 'get',
    params: {
      courseId: courseId,
      fee: freeType,
    },
  });
}

/**
 * 检查用户是否购买了套餐
 * @param packageId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export async function useCheckBuySetMeal(packageId) {
  return await request({
    url: '/Package/IsBuyPackage',
    method: 'get',
    params: {
      packageId: packageId,
    },
  });
}

/**
 * 课程是否收藏
 * @param courseId
 * @returns {Promise<number|any>}
 */
export async function useCheckIsCollect(courseId) {
  let axiosResponse = await request({
    url: '/Course/IsCollect',
    method: 'get',
    params: {
      courseId: courseId,
    },
  });
  if (axiosResponse.data) {
    return axiosResponse.data;
  }
  return 0;
}

/**
 * 汇率换算
 * @param callback
 */
export function useExchangeRate(from, to, amount, callback) {
  request({
    url: '/Common/ExchangeRate',
    method: 'POST',
    params: { from: from, to: to, amount: amount },
  }).then((resp) => {
    callback && callback(resp.data);
  });
}
