import moment from 'moment';

/**
 * 将日期字符串格式化为另一种格式
 * @param dateStr
 * @param fmtStr
 */
export function useFormatDate(dateStr, fmtStr) {
  return moment(new Date(dateStr)).format(fmtStr);
}

/**
 * 拼接管理平台静态资源链接
 * @param uri
 * @returns {*}
 */
export function useUrlCv(uri) {
  return import.meta.env.VITE_APP_IMG_B_BASE_URL + uri;
}

/**
 * 秒转分钟
 * @param second
 */
export function useSecondsToMinutes(second) {
  if (second / 3600 > 1) {
    return moment.utc(second * 1000).format('hh:mm:ss');
  } else {
    return moment.utc(second * 1000).format('mm:ss');
  }
}
