import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress';
import $ from 'jquery';
import { useHead } from '@unhead/vue';
import { use$t } from '@/hooks/language.js';

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_CTX),
  routes: [
    {
      path: '/',
      name: 'index',
      meta: {
        title: 'website.message.01',
      },
      component: () => import('@/views/home/index.vue'),
    },
    {
      path: '/allCourse',
      name: 'allCourse',
      meta: {
        title: 'common.all.course',
      },
      component: () => import('@/views/allCourse/index.vue'),
    },
    {
      path: '/demo',
      name: 'demo',
      component: () => import('@/views/demo/index.vue'),
    },
    {
      path: '/courseDetail/:id',
      name: 'courseDetail',
      meta: {
        title: 'detail.course.course.details',
      },
      component: () => import('@/views/courseDetail/index.vue'),
    },
    {
      path: '/coursePlayer',
      name: 'coursePlayer',
      meta: {
        title: 'detail.records.Play',
      },
      component: () => import('@/views/coursePlayer/index.vue'),
    },
    {
      path: '/individualCenter',
      name: 'individualCenter',
      meta: {
        title: 'individual.center',
      },
      component: () => import('@/views/individualCenter/index.vue'),
    },
    {
      path: '/homework',
      name: 'homework',
      meta: {
        title: 'common.practice.after.class',
      },
      component: () => import('@/views/homework/index.vue'),
    },
    {
      path: '/practiceResult',
      name: 'practiceResult',
      meta: {
        title: 'individual.center.my.homework',
      },
      component: () => import('@/views/practiceResult/index.vue'),
    },
    {
      path: '/dictionaries',
      name: 'dictionaries',
      meta: {
        title: 'header.menu.dictionaries',
      },
      component: () => import('@/views/dictionaries/index.vue'),
    },
    {
      path: '/agreement',
      name: 'agreement',
      meta: {
        title: 'common.protokol.privasi02',
      },
      component: () => import('@/views/agreement/index.vue'),
    },
    {
      path: '/culture',
      name: 'culture',
      meta: {
        title: 'home.latest.news',
      },
      component: () => import('@/views/culture/index.vue'),
    },
    {
      path: '/articleDetail/:id',
      name: 'articleDetail',
      meta: {
        title: 'common.article.detail',
      },
      component: () => import('@/views/articleDetail/index.vue'),
    },
    {
      path: '/pdfView',
      name: 'pdfView',
      meta: {
        title: 'footer.about.us',
      },
      component: () => import('@/views/pdfView/index.vue'),
    },
    {
      path: '/live/timeline',
      name: 'liveTimeline',
      meta: {
        title: 'live.message.01',
      },
      component: () => import('@/views/live/timeline.vue'),
    },
    {
      path: '/live/detail/:id',
      name: 'liveDetail',
      meta: {
        title: 'live.message.02',
      },
      component: () => import('@/views/live/detail.vue'),
    },
    {
      path: '/payment/result/success',
      name: 'paymentSuccess',
      component: () => import('@/views/payment/result/success.vue'),
    },
    {
      path: '/payment/result/failure',
      name: 'paymentFailure',
      component: () => import('@/views/payment/result/failure.vue'),
    },
    {
      path: '/setMeal/list',
      name: 'setMealList',
      meta: {
        title: 'setMeal.message.01',
      },
      component: () => import('@/views/setMeal/list/index.vue'),
    },
    {
      path: '/setMeal/detail/:id',
      name: 'setMealDetail',
      component: () => import('@/views/setMeal/detail/index.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  NProgress.start(); // 开始进度条
  next();
});
router.afterEach((to, from, failure) => {
  NProgress.done(); // 结束进度条
  $('html, body').scrollTop(0);
  //设置头
  if (to.meta.hasOwnProperty('title')) {
    useHead({
      title: use$t(to.meta.title),
    });
  }
});

export default router;
