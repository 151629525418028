import i18n from '@/locales/index.js';
import { useLocalCacheStore } from '@/stores/localCache.js';
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn';
import idID from '@arco-design/web-vue/es/locale/lang/id-id';
import enUS from '@arco-design/web-vue/es/locale/lang/en-us';
import arEg from '@arco-design/web-vue/es/locale/lang/ar-eg';
import esEs from '@arco-design/web-vue/es/locale/lang/es-es';
import frFr from '@arco-design/web-vue/es/locale/lang/fr-fr';
import $ from 'jquery';

export function useInitI18n() {
  const localCache = useLocalCacheStore();
  // 获取浏览器语言
  const preferredLang = localCache.defaultLanguage;
  let lang = preferredLang || navigator.language || navigator.browserLanguage;
  document.body.style.direction = 'ltr';
  if (lang.toLowerCase().indexOf('id') > -1) {
    lang = 'id';
  } else if (lang.toLowerCase().indexOf('ar') > -1) {
    lang = 'ar';
    document.body.style.direction = 'rtl';
  } else if (lang.toLowerCase().indexOf('zh') > -1) {
    lang = 'zh';
  } else if (lang.toLowerCase().indexOf('ur') > -1 || lang.toLowerCase().indexOf('pk') > -1) {
    lang = 'pk';
    document.body.style.direction = 'rtl';
  } else if (lang.toLowerCase().indexOf('fr') > -1) {
    lang = 'fr';
    document.body.style.direction = 'rtl';
  } else if (lang.toLowerCase().indexOf('es') > -1) {
    lang = 'es';
    document.body.style.direction = 'rtl';
  } else {
    lang = 'en';
  }

  console.log('浏览器的语言版本===>', lang);
  localCache.asyncSetLanguage(lang).then(() => {});

  i18n.global.locale.value = lang;

  if (lang === 'zh') {
    return zhCN;
  } else if (lang === 'id') {
    return idID;
  } else if (lang === 'en' || lang === 'pk') {
    return enUS;
  } else if (lang === 'ar') {
    return arEg;
  } else if (lang === 'es') {
    return esEs;
  } else if (lang === 'fr') {
    return frFr;
  }
  return enUS;
}

/**
 * 切换语言环境
 * @param lang
 */
export function useChangeLanguage(lang) {
  //隐藏页面
  $('body').hide();

  const localCache = useLocalCacheStore();
  localCache.asyncSetLanguage(lang).then(() => {
    window.location.reload();
  });
}

/**
 * 获取语言文本
 * @param key
 * return
 */
export function use$t(key, option = {}) {
  return i18n.global.t(key, option);
}
